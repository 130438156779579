import { useQuery } from "@tanstack/react-query";
import { reactQueryOptions } from "src/config/reactQuery.config";
import {
  getProductDetailStock,
  getProductDetailStockPerWarehouse,
  getProductDetailStockWarehouse,
  getProductList,
  getProductListDetail,
  getProductListDetailStockAdjustmentHistory,
  getProductListDetailStockPurchaseHistory,
  getProductVariantList,
  getProductVariantListDetail,
} from "src/services/ProductList.service";
import {
  ProductListDetailRequestBody,
  ProductListDetailStockAdjustmentHistoryParams,
  ProductListDetailStockPerWarehouseParams,
  ProductListDetailStockPurchaseHistoryParams,
  ProductListDetailStockWarehousesParams,
  ProductListParams,
  ProductVariantListDetailParams,
  ProductVariantListParams,
} from "./interfaces";

export const useGetProductList = (params: ProductListParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list", params],
    queryFn: () => getProductList(params),
  });
};

export const useGetProductListDetail = (
  requestBody: ProductListDetailRequestBody
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail", requestBody],
    queryFn: () => getProductListDetail(requestBody),
  });
};

export const useGetProductVariantList = (params: ProductVariantListParams) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-variant-list", params],
    queryFn: () => getProductVariantList(params),
  });
};

export const useGetProductVariantDetail = (
  params: ProductVariantListDetailParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-variant-list", params],
    queryFn: () => getProductVariantListDetail(params),
  });
};

export const useGetProductDetailStock = (
  requestBody: ProductListDetailStockAdjustmentHistoryParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail/product-stock", requestBody],
    queryFn: () => getProductDetailStock(requestBody as any),
  });
};
export const useGetProductDetailStockWarehouses = (
  requestBody: ProductListDetailStockWarehousesParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail/product-stock", requestBody],
    queryFn: () => getProductDetailStockWarehouse(requestBody as any),
  });
};
export const useGetProductDetailStockPerWarehouse = (
  requestBody: ProductListDetailStockPerWarehouseParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail/product-stock", requestBody],
    queryFn: () => getProductDetailStockPerWarehouse(requestBody as any),
  });
};

export const useGetProductStockAdjustmentHistory = (
  requestBody: ProductListDetailStockAdjustmentHistoryParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail/stock-adjustment-history", requestBody],
    queryFn: () => getProductListDetailStockAdjustmentHistory(requestBody),
  });
};

export const useGetProductStockPurchaseHistory = (
  requestBody: ProductListDetailStockPurchaseHistoryParams
) => {
  return useQuery({
    ...reactQueryOptions,
    queryKey: ["product-list-detail/stock-purchase-history", requestBody],
    queryFn: () => getProductListDetailStockPurchaseHistory(requestBody),
  });
};