export const noPagination = {
  limit: 1000000,
  offset: 0,
};

export const defaultPagination = {
  limit: 10,
  offset: 0,
};

export const apiDefaultMetaResponse = {
  current_page: 1,
  first_page: 1,
  first_page_url: "/?page=1",
  last_page: 1,
  last_page_url: "/?page=1",
  next_page_url: null,
  per_page: 1,
  previous_page_url: null,
  total: 0,
};

export const inputPlaceHolder = {
  phone_number: "Input Phone Number (e.g 877-1234-8191)",
};

export const defaultCountryCode = "+62";

export enum USER_TYPE_BACKGROUND {
  Partner = "#FFA1A1",
  Vendor = "#F7F6DF",
}
export enum PAYMENT_METHOD_BACKGROUND {
  full_payment = "#F6E0EE",
  pay_later = "#FFBBBB",
  deposit = "#FFD6A3",
}
export enum PAYMENT_STATUS_BACKGROUND {
  paid = "#A3BCFF",
  unpaid = "#FF8888",
}
export enum TRANSACTION_LABEL_BACKGROUND {
  subout = "#E0DFC6",
  subin = "#E3FFED",
  estimation_order = "#D4D4D4",
}

export enum OrderMethodValue {
  Delivery = "delivery",
  PickUp = "pickup",
}

export enum TransactionFlowValue {
  subin = "Sub-In",
  subout = "Sub-Out",
}

export const ChartStrokeColor = "#A2A9B2";
export const BarChartSize = 20;
export const LineChartStrokeWidth = 2;

export const countryCityDefaultValue = {
  country: "Indonesia",
  city: "Surabaya",
};

export const multiplier = Number(process.env.REACT_APP_FONT_SIZE_MULTIPLIER) || 1.3

export const fontSize = {
  textSm: String(14 * multiplier),
  textXs: String(12 * multiplier),
  text2xs: String(10 * multiplier),
  text3xs: String(8 * multiplier)
}