import { useCallback, useEffect, useRef, useState } from "react";
import { paginationLimitList } from "src/components/PaginationLimitChanger/hooks/usePaginationLimitChanger";
import { useDebounce } from "src/hooks/useDebounce";
import {
  ProductVariantList,
  ProductVariantListResponse,
} from "src/pages/ProductManagement/ProductList/api/interfaces";
import { useGetProductVariantList } from "src/pages/ProductManagement/ProductList/api/queries";
import { convertToCurrency } from "src/utils/convertToCurrency";
import { GroupSearchInputProps } from "..";
import { useGetProductPackageVariantList } from "src/pages/ProductManagement/ProductPackage/api/queries";
import {
  ProductPackageVariantList,
  ProductPackageVariantListResponse,
} from "src/pages/ProductManagement/ProductPackage/api/interfaces";
import { capitalize } from "src/utils/commons";
import { useGetItemVariants } from "src/pages/POS/api/queries";
import { POSItem, POSItemsResponse } from "src/pages/POS/api/interfaces";
import { POSItemType } from "src/pages/POS/hooks/usePOSHandler";

interface UseSearchInputProps extends GroupSearchInputProps {}

export type GroupSearchInputOption = {
  value: string;
  productName: string;
  variant: string;
  category: string;
  subCategory: string;
  sku: string;
  price: number;
  sellPrice: string;
  rentPrice: string;
  type: POSItemType;
};

const useSearchInput = ({
  selectedIds,
  onValueChange,
  type,
}: UseSearchInputProps) => {
  const searchTypeOptions = [
    {
      label: "Search by Variant",
      value: "product-variant",
      placeholder: `Input ${
        type === "all" ? "Product" : capitalize(type)
      } Variant`,
    },
    {
      label: `Search by ${type === "all" ? "Product" : type}`,
      value: "product-name",
      placeholder: `Input ${
        type === "all" ? "Product" : capitalize(type)
      } Name`,
    },
  ];

  const [searchType, setSearchType] = useState(searchTypeOptions[0]?.label);
  const [searchInput, setSearchInput] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isInit, setIsInit] = useState(true);

  const [existingData, setExistingData] = useState<
    ProductVariantList[] | ProductPackageVariantList[] | POSItem[]
  >([]);

  const [pagination, setPagination] = useState({
    limit: paginationLimitList[0],
    offset: 0,
  });

  const debouncedSearchInput = useDebounce({ value: searchInput });

  const {
    data: productVariantListData,
    isLoading: isLoadingProductVariantListData,
  } = useGetProductVariantList({
    ...pagination,
    ...(searchType === searchTypeOptions[0]?.label
      ? { name: debouncedSearchInput }
      : {}),
    ...(searchType === searchTypeOptions[1]?.label
      ? { productName: debouncedSearchInput }
      : {}),
    selectedVariantIds: selectedIds,
  });

  const {
    data: productPackageVariantData,
    isLoading: isLoadingProductPackageVariant,
  } = useGetProductPackageVariantList({
    ...pagination,
    ...(searchType === searchTypeOptions[0]?.label
      ? { name: debouncedSearchInput }
      : {}),
    ...(searchType === searchTypeOptions[1]?.label
      ? { productName: debouncedSearchInput }
      : {}),
    selectedVariantIds: selectedIds,
  });

  const { data: itemVariantData, isLoading: isLoadingItemVariantData } =
    useGetItemVariants({
      ...pagination,
      ...(searchType === searchTypeOptions[0]?.label
        ? { variantName: debouncedSearchInput }
        : {}),
      ...(searchType === searchTypeOptions[1]?.label
        ? { productName: debouncedSearchInput }
        : {}),
    });

  let data:
    | ProductVariantListResponse
    | ProductPackageVariantListResponse
    | POSItemsResponse
    | undefined;
  let isLoading = false;

  if (type === "product") {
    data = productVariantListData;
    isLoading = isLoadingProductVariantListData;
  } else if (type === "package") {
    data = productPackageVariantData;
    isLoading = isLoadingProductPackageVariant;
  } else if (type === "all") {
    data = itemVariantData;
    isLoading = isLoadingItemVariantData;
  }

  const searchOptions: GroupSearchInputOption[] =
    existingData?.map((variant) => {
      let formattedData = {
        value: "",
        productName: "",
        variant: "",
        category: "",
        subCategory: "",
        sku: "",
        price: 0,
        sellPrice: "-",
        rentPrice: "-",
        type: "product" as POSItemType,
      };

      if (type === "product") {
        const v = variant as ProductVariantList;

        formattedData = {
          value: `${v.id}`,
          productName: v.product.name,
          variant: v.name,
          category: v.product.product_category.name,
          subCategory: v.product.product_sub_category.name,
          sku: v.sku,
          price: v.is_purchase ? v.sell_price : v.rent_price,
          sellPrice: v.is_purchase ? `${convertToCurrency(v.sell_price)}` : "-",
          rentPrice: v.is_rent ? `${convertToCurrency(v.rent_price)}` : "-",
          type: "product",
        };
      } else if (type === "package") {
        const v = variant as ProductPackageVariantList;

        formattedData = {
          value: `${v.id}`,
          productName: v.package.name,
          variant: v.name,
          category: v.package.package_category.name,
          subCategory: v.package.package_sub_category.name,
          sku: v.sku,
          price: v.sell_price,
          sellPrice: `${convertToCurrency(v.sell_price)}`,
          rentPrice: "-",
          type: "package",
        };
      } else if (type === "all") {
        const v = variant as POSItem;

        formattedData = {
          value: `${v.id}`,
          productName: v.product.name,
          variant: v.name,
          category: v.product.category.name,
          subCategory: v.product.sub_category.name,
          sku: v.sku,
          price: v.is_purchase ? v.sell_price : v.rent_price,
          sellPrice: v.is_purchase ? `${convertToCurrency(v.sell_price)}` : "-",
          rentPrice: v.is_rent ? `${convertToCurrency(v.rent_price)}` : "-",
          type: v.type,
        };
      }

      return formattedData;
    }) ?? [];

  const hasMoreData = data?.meta?.last_page !== data?.meta?.current_page;

  const clearState = () => {
    setIsInit(true);
    setExistingData([]);
    setPagination({
      limit: paginationLimitList[0],
      offset: 0,
    });
  };

  const handleLoadMore = () => {
    let tempExistingData = [...existingData] as
      | ProductVariantList[]
      | ProductPackageVariantList[];

    setTimeout(() => {
      setExistingData(tempExistingData);
      setPagination({
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
      });

      setIsOpen(true);
      inputRef?.current?.focus();
    }, 250);
  };

  useEffect(() => {
    if (!isOpen && !isInit) {
      clearState();
    }
  }, [isOpen, isInit]);

  useEffect(() => {
    if (isOpen && data?.data) {
      const responseData = data?.data as
        | ProductVariantList[]
        | ProductPackageVariantList[];

      if (isInit || searchInput) {
        setExistingData([...responseData] as
          | ProductVariantList[]
          | ProductPackageVariantList[]);
        setIsInit(false);
      } else {
        if (pagination.offset > 0) {
          setExistingData(
            (prevState) =>
              [...prevState, ...responseData] as
                | ProductVariantList[]
                | ProductPackageVariantList[]
          );
        }
      }
    }
  }, [isOpen, data, isInit, pagination.offset, searchInput]);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsOpen(false);
      setIsInit(true);
    }, 200);
  }, []);

  const handleSelectOption = useCallback(
    (selectedOption: GroupSearchInputOption) => {
      onValueChange(selectedOption);

      setTimeout(() => {
        inputRef?.current?.blur();
      }, 0);
    },
    [onValueChange]
  );

  const handleChangeSearchInput = (search: string) => {
    clearState();
    setSearchInput(search);
  };

  return {
    handleChangeSearchInput,
    searchTypeOptions,
    inputRef,
    searchType,
    setSearchType,
    searchInput,
    isOpen,
    setIsOpen,
    isLoading,
    handleBlur,
    handleSelectOption,
    searchOptions,
    hasMoreData,
    handleLoadMore,
  };
};

export default useSearchInput;
