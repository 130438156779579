import AngieFiorLogo1 from "src/assets/images/angie_fior_logo_1.png";
import AngieFiorLogo2 from "src/assets/images/angie_fior_logo_2.png";

export const appAsset = {
  appName: "Angie Fior",
  appLogo1: AngieFiorLogo1,
  appLogo2: AngieFiorLogo2,
  appTabIconHref: "/favicon.ico",
  appleTouchIconLinkHref: "/logo192.png",
  bankName: "CV Kreasi Karya Flora",
  bankAccount: "BCA 0888 000 308",
  contact: {
    isEmail: false,
    value: ["@angiefior.id", "@bungapapansurabaya"],
    phone: "+62 811 3536 888"
  }
};