import { lazy, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import LoadingPage from "src/components/LoadingPage";
import GuestRoute from "src/Routers/GuestRoute";
import AuthRoute from "../Routers/AuthRoute";
import { RoutePaths } from "./routePaths";
import { useCurrentUser } from "./SignIn/api/queries";
import Welcome from "./Welcome";
import EditOrder from "./OrderManagement/OrderList/EditOrder";
import { appAsset } from "src/appAsset";

const UnAuthenticated = lazy(() => import("./UnAuthenticated"));

const Overview = lazy(() => import("./Overview"));
const Notification = lazy(() => import("./Overview/Notification"));
const ChangeLog = lazy(() => import("./ChangeLog"));

// POS
const POS = lazy(() => import("./POS"));
const POSTransactionSummary = lazy(() => import("./POS/TransactionSummary"));
const POSPayment = lazy(() => import("./POS/Payment"));
// POS

// Order Management
const OrderManagementOrderList = lazy(
  () => import("./OrderManagement/OrderList")
);
const OrderManagementOrderListDetail = lazy(
  () => import("./OrderManagement/OrderList/OrderDetail")
);
const OrderManagementCustomerTransactionList = lazy(
  () => import("./OrderManagement/CustomerTransactionList")
);
const OrderManagementCustomerTransactionListDetail = lazy(
  () =>
    import(
      "./OrderManagement/CustomerTransactionList/CustomerTransactionListDetail"
    )
);
const OrderManagementSubOutTransactionList = lazy(
  () => import("./OrderManagement/SubOutTransactionList")
);
const OrderManagementSubOutTransactionListDetail = lazy(
  () =>
    import(
      "./OrderManagement/SubOutTransactionList/SubOutTransactionListDetail"
    )
);
const OrderManagementReturnItemList = lazy(
  () => import("./OrderManagement/ReturnItemList")
);
const OrderManagementReturnItemListDetail = lazy(
  () => import("./OrderManagement/ReturnItemList/ReturnItemListDetail")
);
// Order Management

// User Management
const UserManagementCustomerList = lazy(
  () => import("./UserManagement/CustomerList")
);
const UserManagementCreateCustomerList = lazy(
  () => import("./UserManagement/CustomerList/CreateCustomerList")
);
const UserManagementEditCustomerList = lazy(
  () => import("./UserManagement/CustomerList/EditCustomerList")
);
const UserManagementCustomerListDetail = lazy(
  () => import("./UserManagement/CustomerList/CustomerListDetail")
);

const UserManagementVendorOrPartnerList = lazy(
  () => import("./UserManagement/ManageVendorOrPartnerList")
);
const UserManagementVendorOrPartnerListDetail = lazy(
  () =>
    import(
      "./UserManagement/ManageVendorOrPartnerList/VendorOrPartnerListDetail"
    )
);

const UserManagementSupplierList = lazy(
  () => import("./UserManagement/ManageSupplierList")
);
const UserManagementSupplierListDetail = lazy(
  () => import("./UserManagement/ManageSupplierList/ManageSupplierListDetail")
);
// User Management

// Product Management
const ProductManagementStockPurchase = lazy(
  () => import("./ProductManagement/StockPurchase")
);
const ProductManagementCreateStockPurchase = lazy(
  () => import("./ProductManagement/StockPurchase/CreateStockPurchase")
);
const ProductManagementEditStockPurchase = lazy(
  () => import("./ProductManagement/StockPurchase/EditStockPurchase")
);
const ProductManagementStockPurchaseDetail = lazy(
  () => import("./ProductManagement/StockPurchase/StockPurchaseDetail")
);

const ProductManagementInventory = lazy(
  () => import("./ProductManagement/Inventory")
);

const ProductManagementProductList = lazy(
  () => import("./ProductManagement/ProductList")
);
const ProductManagementCreateProductList = lazy(
  () => import("./ProductManagement/ProductList/CreateProductList")
);
const ProductManagementEditProductList = lazy(
  () => import("./ProductManagement/ProductList/EditProductList")
);
const ProductManagementEditProductListVariant = lazy(
  () => import("./ProductManagement/ProductList/EditProductListVariant")
);
const ProductManagementProductListDetail = lazy(
  () => import("./ProductManagement/ProductList/ProductListDetail")
);

const ProductManagementProductPackage = lazy(
  () => import("./ProductManagement/ProductPackage")
);
const ProductManagementCreateProductPackage = lazy(
  () => import("./ProductManagement/ProductPackage/CreateProductPackage")
);
const ProductManagementEditProductPackage = lazy(
  () => import("./ProductManagement/ProductPackage/EditProductPackage")
);
const ProductManagementProductPackageDetail = lazy(
  () => import("./ProductManagement/ProductPackage/ProductPackageDetail")
);

const ProductManagementWarehouse = lazy(
  () => import("./ProductManagement/Warehouse")
);
// Product Management

// Report
const OverviewReport = lazy(() => import("./Report/OverviewReport"));
const SalesReport = lazy(() => import("./Report/SalesReport"));
const ProductUsageReport = lazy(() => import("./Report/ProductUsageReport"));
const ExpenseAndIncomeReport = lazy(
  () => import("./Report/ExpenseAndIncomeReport")
);
const PnLReport = lazy(() => import("./Report/PnLReport"));

// Report

// Promo Code
const PromoCode = lazy(() => import("./PromoCode"));
const CreatePromoCode = lazy(() => import("./PromoCode/CreatePromoCode"));
const EditPromoCode = lazy(() => import("./PromoCode/EditPromoCode"));
const PromoCodeDetail = lazy(() => import("./PromoCode/PromoCodeDetail"));
// Promo Code

// Admin Management
const AdminManagementAdminList = lazy(
  () => import("./AdminManagement/AdminList")
);
const AdminManagementCreateAdminList = lazy(
  () => import("./AdminManagement/AdminList/CreateAdminList")
);
const AdminManagementEditAdminList = lazy(
  () => import("./AdminManagement/AdminList/EditAdminList")
);
const AdminManagementAdminListDetail = lazy(
  () => import("./AdminManagement/AdminList/AdminListDetail")
);

const AdminManagementAdminActionLog = lazy(
  () => import("./AdminManagement/AdminActionLog")
);

const AdminManagementAdminRole = lazy(
  () => import("./AdminManagement/AdminRole")
);
const AdminManagementCreateAdminRole = lazy(
  () => import("./AdminManagement/AdminRole/CreateAdminRole")
);
const AdminManagementEditAdminRole = lazy(
  () => import("./AdminManagement/AdminRole/EditAdminRole")
);
// Admin Management

// Master Data
const MasterDataVendorOrPartnerList = lazy(
  () => import("./MasterData/VendorOrPartnerList")
);
const MasterDataCreateVendorOrPartnerList = lazy(
  () => import("./MasterData/VendorOrPartnerList/CreateVendorOrPartnerList")
);
const MasterDataEditVendorOrPartnerList = lazy(
  () => import("./MasterData/VendorOrPartnerList/EditVendorOrPartnerList")
);

const MasterDataSupplierList = lazy(() => import("./MasterData/SupplierList"));
const MasterDataCreateSupplierList = lazy(
  () => import("./MasterData/SupplierList/CreateSupplierList")
);
const MasterDataEditSupplierList = lazy(
  () => import("./MasterData/SupplierList/EditSupplierList")
);

const MasterDataBusinessOperation = lazy(
  () => import("./MasterData/BusinessOperation")
);
const MasterDataCreateBusinessOperation = lazy(
  () => import("./MasterData/BusinessOperation/CreateBusinessOperation")
);
const MasterDataEditBusinessOperation = lazy(
  () => import("./MasterData/BusinessOperation/EditBusinessOperation")
);

const MasterDataAdditionalFee = lazy(
  () => import("./MasterData/AdditionalFee")
);

const MasterDataWarehouseData = lazy(
  () => import("./MasterData/WarehouseData")
);

const MasterDataProductCategory = lazy(
  () => import("./MasterData/ProductCategory")
);

const MasterDataPackageCategory = lazy(
  () => import("./MasterData/PackageCategory")
);

const MasterDataCustomerType = lazy(() => import("./MasterData/CustomerType"));

const MasterDataItemStockUnit = lazy(
  () => import("./MasterData/ItemStockUnit")
);

const ExpenseAndIncomeCategory = lazy(
  () => import("./MasterData/ExpenseAndIncomeCategory")
);

// Master Data

const SignIn = lazy(() => import("./SignIn"));

const App = () => {
  const currentUser = useCurrentUser();

  const { appName, appTabIconHref, appleTouchIconLinkHref } = appAsset;

  useEffect(() => {
    document.title = appName;

    const faviconLink = document.querySelector(
      'link[rel="icon"]'
    ) as HTMLLinkElement;

    if (faviconLink) {
      faviconLink.href = appTabIconHref;
    }

    const appleTouchIconLink = document.querySelector(
      'link[rel="apple-touch-icon"]'
    ) as HTMLLinkElement;
    if (appleTouchIconLink) {
      appleTouchIconLink.href = appleTouchIconLinkHref;
    }
  }, [appName, appTabIconHref, appleTouchIconLinkHref]);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route element={<GuestRoute currentUser={currentUser} />}>
          <Route path={RoutePaths.SignIn} element={<SignIn />} />
        </Route>

        <Route element={<AuthRoute currentUser={currentUser} />}>
          <Route
            path={RoutePaths.UnAuthenticated}
            element={<UnAuthenticated />}
          />
          <Route path={RoutePaths.Welcome} element={<Welcome />} />

          <Route path={RoutePaths.Overview} element={<Overview />} />
          <Route path={RoutePaths.Notification} element={<Notification />} />
          <Route path={RoutePaths.ChangeLog} element={<ChangeLog />} />

          {/* Order Management */}
          <Route
            path={RoutePaths.OrderManagementOrderList}
            element={<OrderManagementOrderList />}
          />
          <Route
            path={`${RoutePaths.OrderManagementOrderList}/:id`}
            element={<OrderManagementOrderListDetail />}
          />
          <Route
            path={`${RoutePaths.OrderManagementCustomerTransactionList}/:tabIndex?`}
            element={<OrderManagementCustomerTransactionList />}
          />
          <Route
            path={`${RoutePaths.OrderManagementCustomerTransactionList}/:id/detail/:tabIndex?`}
            element={<OrderManagementCustomerTransactionListDetail />}
          />
          <Route
            path={`${RoutePaths.OrderManagementCustomerTransactionList}/:id/edit`}
            element={<EditOrder />}
          />
          <Route
            path={RoutePaths.OrderManagementSubOutTransactionList}
            element={<OrderManagementSubOutTransactionList />}
          />
          <Route
            path={`${RoutePaths.OrderManagementSubOutTransactionList}/:id`}
            element={<OrderManagementSubOutTransactionListDetail />}
          />
          <Route
            path={RoutePaths.OrderManagementReturnItemList}
            element={<OrderManagementReturnItemList />}
          />
          <Route
            path={`${RoutePaths.OrderManagementReturnItemList}/:id`}
            element={<OrderManagementReturnItemListDetail />}
          />
          <Route
            path={`${RoutePaths.OrderManagementOrderList}/:id/edit`}
            element={<EditOrder />}
          />
          {/* Order Management */}

          {/* POS */}
          <Route path={RoutePaths.POS} element={<POS />} />
          <Route
            path={RoutePaths.POSTransactionSummary}
            element={<POSTransactionSummary />}
          />
          <Route path={RoutePaths.POSPayment} element={<POSPayment />} />

          {/* POS */}

          {/* User Management */}
          <Route
            path={RoutePaths.UserManagementCustomerList}
            element={<UserManagementCustomerList />}
          />
          <Route
            path={RoutePaths.UserManagementCreateCustomerList}
            element={<UserManagementCreateCustomerList />}
          />
          <Route
            path={`${RoutePaths.UserManagementCustomerList}/:id/edit`}
            element={<UserManagementEditCustomerList />}
          />
          <Route
            path={`${RoutePaths.UserManagementCustomerList}/:id`}
            element={<UserManagementCustomerListDetail />}
          />

          <Route
            path={RoutePaths.UserManagementPartnerOrVendorList}
            element={<UserManagementVendorOrPartnerList />}
          />
          <Route
            path={`${RoutePaths.UserManagementPartnerOrVendorList}/:id`}
            element={<UserManagementVendorOrPartnerListDetail />}
          />

          <Route
            path={RoutePaths.UserManagementSupplierList}
            element={<UserManagementSupplierList />}
          />
          <Route
            path={`${RoutePaths.UserManagementSupplierList}/:id`}
            element={<UserManagementSupplierListDetail />}
          />

          {/* User Management */}

          {/* Product Management */}
          <Route
            path={RoutePaths.ProductManagementStockPurchase}
            element={<ProductManagementStockPurchase />}
          />
          <Route
            path={RoutePaths.ProductManagementCreateStockPurchase}
            element={<ProductManagementCreateStockPurchase />}
          />
          <Route
            path={`${RoutePaths.ProductManagementStockPurchase}/:id/edit`}
            element={<ProductManagementEditStockPurchase />}
          />
          <Route
            path={`${RoutePaths.ProductManagementStockPurchase}/:id`}
            element={<ProductManagementStockPurchaseDetail />}
          />

          <Route
            path={RoutePaths.ProductManagementInventory}
            element={<ProductManagementInventory />}
          />

          <Route
            path={RoutePaths.ProductManagementProductList}
            element={<ProductManagementProductList />}
          />
          <Route
            path={RoutePaths.ProductManagementCreateProductList}
            element={<ProductManagementCreateProductList />}
          />
          <Route
            path={`${RoutePaths.ProductManagementProductListVariant}/:id/edit`}
            element={<ProductManagementEditProductListVariant />}
          />
          <Route
            path={`${RoutePaths.ProductManagementProductList}/:id/edit`}
            element={<ProductManagementEditProductList />}
          />
          <Route
            path={`${RoutePaths.ProductManagementProductList}/:id`}
            element={<ProductManagementProductListDetail />}
          />

          <Route
            path={RoutePaths.ProductManagementProductPackage}
            element={<ProductManagementProductPackage />}
          />
          <Route
            path={RoutePaths.ProductManagementCreateProductPackage}
            element={<ProductManagementCreateProductPackage />}
          />
          <Route
            path={`${RoutePaths.ProductManagementProductPackage}/:id/edit`}
            element={<ProductManagementEditProductPackage />}
          />
          <Route
            path={`${RoutePaths.ProductManagementProductPackage}/:id`}
            element={<ProductManagementProductPackageDetail />}
          />

          <Route
            path={RoutePaths.ProductManagementWarehouse}
            element={<ProductManagementWarehouse />}
          />
          {/* Product Management */}

          {/* Report */}
          <Route
            path={RoutePaths.ReportOverviewReport}
            element={<OverviewReport />}
          />
          <Route
            path={RoutePaths.ReportSalesReport}
            element={<SalesReport />}
          />
          <Route
            path={`${RoutePaths.ReportProductUsageReport}/:sortType?`}
            element={<ProductUsageReport />}
          />
          <Route
            path={RoutePaths.ReportExpenseAndIncomeReport}
            element={<ExpenseAndIncomeReport />}
          />
          <Route path={RoutePaths.ReportPnLReport} element={<PnLReport />} />
          {/* Report */}

          {/* Promo Code */}
          <Route path={RoutePaths.PromoCode} element={<PromoCode />} />
          <Route
            path={RoutePaths.CreatePromoCode}
            element={<CreatePromoCode />}
          />
          <Route
            path={`${RoutePaths.PromoCode}/:id/edit`}
            element={<EditPromoCode />}
          />
          <Route
            path={`${RoutePaths.PromoCode}/:id`}
            element={<PromoCodeDetail />}
          />
          {/* Promo Code */}

          {/* Admin Management */}
          <Route
            path={RoutePaths.AdminManagementAdminList}
            element={<AdminManagementAdminList />}
          />
          <Route
            path={RoutePaths.AdminManagementCreateAdminList}
            element={<AdminManagementCreateAdminList />}
          />
          <Route
            path={`${RoutePaths.AdminManagementAdminList}/:id/edit`}
            element={<AdminManagementEditAdminList />}
          />
          <Route
            path={`${RoutePaths.AdminManagementAdminList}/:id`}
            element={<AdminManagementAdminListDetail />}
          />

          <Route
            path={RoutePaths.AdminManagementAdminActionLog}
            element={<AdminManagementAdminActionLog />}
          />

          <Route
            path={RoutePaths.AdminManagementAdminRole}
            element={<AdminManagementAdminRole />}
          />
          <Route
            path={RoutePaths.AdminManagementCreateAdminRole}
            element={<AdminManagementCreateAdminRole />}
          />
          <Route
            path={`${RoutePaths.AdminManagementAdminRole}/:id/edit`}
            element={<AdminManagementEditAdminRole />}
          />
          {/* Admin Management */}

          {/* Master Data */}
          <Route
            path={RoutePaths.MasterDataVendorOrPartnerList}
            element={<MasterDataVendorOrPartnerList />}
          />
          <Route
            path={RoutePaths.MasterDataCreateVendorOrPartnerList}
            element={<MasterDataCreateVendorOrPartnerList />}
          />
          <Route
            path={`${RoutePaths.MasterDataVendorOrPartnerList}/:id/edit`}
            element={<MasterDataEditVendorOrPartnerList />}
          />

          <Route
            path={RoutePaths.MasterDataSupplierList}
            element={<MasterDataSupplierList />}
          />
          <Route
            path={RoutePaths.MasterDataCreateSupplierList}
            element={<MasterDataCreateSupplierList />}
          />
          <Route
            path={`${RoutePaths.MasterDataSupplierList}/:id/edit`}
            element={<MasterDataEditSupplierList />}
          />

          <Route
            path={RoutePaths.MasterDataBusinessOperation}
            element={<MasterDataBusinessOperation />}
          />
          <Route
            path={RoutePaths.MasterDataCreateBusinessOperation}
            element={<MasterDataCreateBusinessOperation />}
          />
          <Route
            path={`${RoutePaths.MasterDataBusinessOperation}/:id/edit`}
            element={<MasterDataEditBusinessOperation />}
          />

          <Route
            path={RoutePaths.MasterDataAdditionalFee}
            element={<MasterDataAdditionalFee />}
          />

          <Route
            path={RoutePaths.MasterDataWarehouseData}
            element={<MasterDataWarehouseData />}
          />

          <Route
            path={RoutePaths.MasterDataProductCategory}
            element={<MasterDataProductCategory />}
          />

          <Route
            path={RoutePaths.MasterDataPackageCategory}
            element={<MasterDataPackageCategory />}
          />

          <Route
            path={RoutePaths.MasterDataCustomerType}
            element={<MasterDataCustomerType />}
          />

          <Route
            path={RoutePaths.MasterDataItemStockUnit}
            element={<MasterDataItemStockUnit />}
          />

          <Route
            path={RoutePaths.MasterDataExpenseAndIncomeCategory}
            element={<ExpenseAndIncomeCategory />}
          />

          {/* Master Data */}
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;