export const snakeToCamel = (snakeStr: string = ""): string => {
  if (snakeStr.split(".").length > 1) {
    return snakeStr;
  }

  return snakeStr
    .toLowerCase()
    .replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
};

export const camelToSnake = (camelStr: string = ""): string => {
  return camelStr.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export function convertCamelToSnakeCaseObj<TVal>(obj: Record<string, TVal>) {
  const keys = Object.keys(obj);
  const result: Record<string, TVal> = {};

  for (const key of keys) {
    let snakeCased = camelToSnake(key);
    result[snakeCased] = obj[key];
  }

  return result;
}